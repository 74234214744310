<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.FUELS.TITLE") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link
            v-permission="['tank-charges-create']"
            to="add"
            class="btn btn-light-success font-weight-bolder ml-2"
            ><i class="flaticon2-plus"></i>
            {{ $t("LABELS.ADD_NEW") }}</router-link
          >
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
          <template v-slot:prepend>
            <b-col class="pb-0" :md="4">
              <b-form-group id="date-from-group" label-for="date-from-input">
                <FormDatepicker
                  id="date-to-input"
                  type="text"
                  name="date_from"
                  :i18n="{ placeholder: 'FORM_LABELS.DATE_FROM' }"
                  :validateState="{}"
                  v-model="filters.dateFrom"
                ></FormDatepicker>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="4">
              <b-form-group id="date-from-group" label-for="date-from-input">
                <FormDatepicker
                  id="date-from-input"
                  type="text"
                  name="date_to"
                  :i18n="{ placeholder: 'FORM_LABELS.DATE_TO' }"
                  :validateState="{}"
                  v-model="filters.dateTo"
                ></FormDatepicker>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="4">
              <b-form-group
                id="gasStations-group"
                label-for="gasStations-input"
              >
                <FormSelect
                  id="gasStations-input"
                  :placeholder="$t('MENU.ITEM.TANKS.TANK')"
                  type="select"
                  name="gasStations"
                  :options="gasStations"
                  clearable="true"
                  v-model="filters.gasTankStationId"
                ></FormSelect>
              </b-form-group>
            </b-col>
          </template>
        </SearchTools>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingTankCharge"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
          }"
          @update:options="getDataFromApi()"
          class="px-6 elevation-1"
        >
          <template v-slot:[`item.dateCharged`]="{ item }">
            {{ item.dateCharged | moment("DD.MM.YYYY") }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['tank-charges-update'])"
              :to="{ name: 'edit-tank-charges', params: { id: item.id } }"
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-2" fab dark x-small v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("LABELS.EDIT") }}</span>
              </v-tooltip>
            </router-link>
            <template v-else>Няма права</template>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";

import SearchTools from "@/core/components/table/SearchTools.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";

import { FETCH_TANK_CHARGES } from "@/modules/tank-charges/store/tankcharges.module";
import { FETCH_TANKS } from "@/modules/tanks/store/tanks.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  components: {
    SearchTools,
    FormDatepicker,
    FormSelect,
  },
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      items: [],
      totalItems: 100,
      searchQuery: "",
      gasStations: [],
      options: {
        sortBy: ["dateCharged"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 30,
        totalItems: 100,
      },
      filters: {
        dateFrom: null,
        dateTo: null,
        gasTankStationId: null,
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      columns: {},
      cancelSource: null,
      headers: [
        {
          text: this.$i18n.t("FORM_LABELS.DATE"),
          value: "dateCharged",
        },
        {
          text: this.$i18n.t("MENU.ITEM.TANKS.FROM"),
          value: "gasTankStationName",
        },
        {
          text: this.$i18n.t("MENU.ITEM.EXP.FUELABLE_TYPE"),
          value: "fuelableName",
        },
        {
          text: this.$i18n.t("FORM_PLACEHOLDERS.STAFF_NAME"),
          value: "staffName",
        },
        { text: this.$i18n.t("MENU.ITEM.FUELS.LITERS"), value: "liters" },
        { text: this.$i18n.t("MENU.ITEM.FUELS.MILEAGES"), value: "mileage" },
        { text: this.$i18n.t("MENU.ITEM.FUELS.EXPENSE"), value: "expense" },
        {
          align: "right",
          text: this.$i18n.t("MENU.ITEM.ACTIONS"),
          value: "actions",
          sortable: false,
          width: "10%",
        },
      ],
    };
  },
  watch: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.FUELS.TITLE"),
        route: { name: "list-tank-charges" },
      },
      { title: this.$i18n.t("MENU.ITEM.ASSETS.LIST") },
    ]);
    this.$store.dispatch(FETCH_TANKS).then((data) => {
      this.gasStations = data.data.items;
    });
  },
  computed: {
    ...mapGetters(["getStaffVehicle", "isLoadingTankCharge"]),
    params() {
      return {
        ...this.options,
        ...this.filters,
        query: this.searchQuery,
      };
    },
    tableHeaders() {
      return this.headers;
    },
  },
  methods: {
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      if (this.options.page == 1) {
        this.getDataFromApi();
      } else {
        this.options.page = 1;
      }
    },
    doClear() {
      this.searchQuery = "";
      if (this.options.page == 1) {
        this.getDataFromApi();
      } else {
        this.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.$url.transformParams(params);
      vm.$store
        .dispatch(FETCH_TANK_CHARGES, apiParams)
        .then((data) => {
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch((response) => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found",
            });
            this.$router.push({ name: "list-tank-charges" });
          }
        });
    }
  },
};
</script>
